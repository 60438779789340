import Parse from "parse";















export class Message extends Parse.Object {
  constructor(data) {
    super("RideSharingMessage", data );
  }
}

Parse.Object.registerSubclass("RideSharingMessage", Message);
