const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\SearchNearPlaceContent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import { useParams } from "@opendash/router";
import React from "react";
import {
  ListContainer,
  Place,
  SearchResultList,
  TitleContainer,
  useParseGet,
  useRideSearchPlace,
} from "..";

export function SearchNearPlaceContent() {
  const t = useTranslation();
  const { placeId } = useParams();

  const { result: place } = useParseGet(Place, placeId);

  const [data, error, loading] = useRideSearchPlace(placeId);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
        , t("rs:search.offers_near_place", {
          name: _optionalChain([place, 'optionalAccess', _ => _.get, 'call', _2 => _2("name")]),
        })
      )
      , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
        , React.createElement(SearchResultList, {
          result: _optionalChain([data, 'optionalAccess', _3 => _3.results]) || [],
          loading: loading,
          startLocation: _optionalChain([place, 'optionalAccess', _4 => _4.get, 'call', _5 => _5("location")]),
          endLocation: null,
          date: _optionalChain([data, 'optionalAccess', _6 => _6.date]),
          weekday: _optionalChain([data, 'optionalAccess', _7 => _7.weekday]),
          time: _optionalChain([data, 'optionalAccess', _8 => _8.time]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        )
      )
    )
  );
}
