import Parse from "parse";




















export class Search extends Parse.Object {
  constructor(data) {
    super("RideSharingSearch", data );
  }
}

Parse.Object.registerSubclass("RideSharingSearch", Search);
