const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\AuthLoginForm.tsx";import {

  FormGenerator,
  useTranslation,
} from "@opendash/core";
import React from "react";
import { useCloudCodeTenantOptions } from "..";

export const AuthLoginForm = ({ onSubmit }) => {
  const t = useTranslation();

  const { result, loading } = useCloudCodeTenantOptions();

  if (loading || result.length === 0) {
    return null;
  }

  return (
    React.createElement(FormGenerator, {
      onSubmit: (data) => {
        onSubmit(data);
      },
      submit: {
        children: t("opendash:auth.fields.login_submit"),
        type: "primary",
        htmlType: "submit",
        style: {
          width: "100%",
        },
      },
      settings: {
        hideLabels: true,
      },
      elements: [
        {
          key: "username",
          type: "input",
          label: t("opendash:auth.fields.email"),
          settings: {
            placeholder: t("opendash:auth.fields.email"),
          },
          rules: [
            {
              required: true,
              message: t("opendash:auth.fields.email_required"),
            },
          ],
        },
        {
          key: "password",
          type: "input.password",
          label: t("opendash:auth.fields.password"),
          settings: {
            placeholder: t("opendash:auth.fields.password"),
          },
          rules: [
            {
              required: true,
              message: t("opendash:auth.fields.password_required"),
            },
          ],
        },
      ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
    )
  );
};
