const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\RideOfferRoute.tsx";import { useUrlParam } from "@opendash/core";
import { Navigate } from "@opendash/router";
import React from "react";
import {
  OfflineMessage,
  RideOfferCreate,
  RideOfferOverview,
  getUserProfile,
  useIsOnline,
} from "..";

export function RideOfferRoute({}) {
  const isOnline = useIsOnline();
  const isSimple = getUserProfile().offerDisabled;

  const [createView, setCreateView] = useUrlParam("create", false, "json");

  if (!isOnline) {
    return React.createElement(OfflineMessage, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} );
  }

  if (isSimple) {
    return React.createElement(Navigate, { to: "/ride-sharing/search", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} );
  }

  if (createView) {
    return React.createElement(RideOfferCreate, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}} );
  }

  return React.createElement(RideOfferOverview, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} );
}
