const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\SearchNearPlaceRoute.tsx";import React from "react";
import { OfflineMessage, SearchNearPlaceContent, useIsOnline } from "..";

export function SearchNearPlaceRoute() {
  const isOnline = useIsOnline();

  if (!isOnline) {
    return React.createElement(OfflineMessage, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 8}} );
  }

  return React.createElement(SearchNearPlaceContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}} );
}
