import { usePromise } from "@opendash/core";
import Parse from "parse";
import React from "react";
import { Location } from "..";
import { runParseGeocodeReverse } from "../cloud-code";







export function useCloudCodeGeoCodingReverse(
  latitude,
  longitude,
  icon
) {
  const [result, error, loading] = usePromise(
    async () =>
      !(latitude && longitude)
        ? []
        : await runParseGeocodeReverse({
            latitude,
            longitude,
            limit: 1,
          }),
    [latitude, longitude]
  );

  return React.useMemo(() => {
    return {
      error,
      loading,
      result: (result || []).map((result) => {
        return new Location({
          title: result.title,
          description: result.description,
          location: new Parse.GeoPoint(result.location),
          icon: icon,
        });
      }),
    };
  }, [result, error, loading]);
}
