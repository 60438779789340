const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\MapRoutes.tsx";import React from "react";
import { Layer, Source } from "react-map-gl";







export const MapRoutes = React.memo(function MapRoutes({ routes = [] }) {
  return (
    React.createElement(React.Fragment, null
      , routes.map((route, i) => {
        return (
          React.createElement(Source, {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: route.points,
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}

            , React.createElement(Layer, {
              id: `route-${i}`,
              type: "line",
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": window
                  .getComputedStyle(document.documentElement)
                  .getPropertyValue("--ant-primary-color"),
                "line-width": 5,
                "line-opacity": 0.6,
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
            )
          )
        );
      })
    )
  );
});
