import buffer from "@turf/buffer";
import Parse from "parse";


export function createParsePolygonFromRoute(
  route,
  distance = 200
) {
  try {
    if (route) {
      return new Parse.Polygon(
        buffer(route.points, distance, {
          units: "meters",
        }).geometry.coordinates[0]
      );
    }
  } catch (error) {
    console.error(error);
  }

  return null;
}
