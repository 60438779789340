import * as styled from "styled-components";

export const AppGlobalStyle = styled.createGlobalStyle`
  :root {
    --kommuter-navbar-bottom-height: 60px;
  }

  .ant-avatar-lg.ant-avatar-icon > .anticon {
    padding-top: 8px;
  }

  #opendash-footer {
    display: ${(p) => (p.footer ? "block" : "none")};
    padding-bottom: 60px;
  }
`;
