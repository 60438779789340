import { usePromise } from "@opendash/core";
import dayjs from "dayjs";
import { useCurrentPosition } from "..";
import { runParseSearchNearby } from "../cloud-code";

export function useRideSearchLocal() {
  const [latitude, longitude] = useCurrentPosition();

  const now = dayjs();

  return usePromise(
    () =>
      runParseSearchNearby({
        start_latitude: latitude,
        start_longitude: longitude,
        date: now.valueOf(),
        time: now.format("HH:mm"),
        weekday: now.day(),

        // start_latitude: 50.8742971,
        // start_longitude: 8.0151895,
        // date: 1684188000000,
        // time: "10:00",
        // weekday: 2,
      }),
    [latitude, longitude, now.minute()]
  );
}
