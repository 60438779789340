import { PUBLIC_TRANSPORT_HAFAS_API_URL } from "..";

export async function fetchPublicTransport(
  endpoint,
  params
) {
  const qs = new URLSearchParams(params);

  return await fetch(PUBLIC_TRANSPORT_HAFAS_API_URL + endpoint + "?" + qs).then(
    (response) => response.json()
  );
}
