import { useStorage } from "@opendash/core";
import { LocationStorageOptions } from "..";

export function useRideSearchStart() {
  return useStorage(
    "device",
    "ride-sharing:search-start",
    undefined,
    LocationStorageOptions
  );
}
