const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\MessengerChat.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useElementSize, useTranslation, useUrlParam } from "@opendash/core";
import { $parse } from "@opendash/plugin-parse";
import { Divider, List } from "antd";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";
import {
  ErrorMessage,
  MessengerChatInput,
  MessengerListItem,
  kommuter,
  useChat,
  useChatMessages,
} from "..";

const Container = styled.div``;

const ChatHeadContainer = styled.div`
  background: white;
  padding: 0 12px;
  padding-top: env(safe-area-inset-top);
  margin-top: calc(-1 * env(safe-area-inset-top));
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
`;

const Empty = styled.div`
  padding: 50px;
  text-align: center;
`;

const InputContainer = styled.div`
  position: fixed;

  bottom: calc(
    var(--kommuter-navbar-bottom-height) + env(safe-area-inset-bottom)
  );
  left: 0;
  right: 0;

  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);

  z-index: 1;

  padding: 10px;
`;

const MessagesContainer = styled.div`
  height: calc(
    100vh - var(--kommuter-navbar-bottom-height) - env(safe-area-inset-bottom) -
      ${(p) => p.cHeight}px
  );
  padding: 10px;
  overflow-y: auto;
`;

const MessageContainer = styled.div`
  text-align: ${(p) => p.side};
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
`;

const MessageBubble = styled.div`
  display: inline-block;

  padding: 3px 10px;
  border-radius: 2px;
  max-width: 80%;
  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;

  overflow-wrap: break-word;
`;

const MessageTime = styled.span`
  margin-left: 10px;
  margin-top: 5px;
  margin-right: -5px;

  float: right;
  font-size: 0.7em;
`;

const MessageDate = styled.div`
  /* text-align: center; */

  span {
    font-weight: normal;
    font-size: 0.7em;
    letter-spacing: 1px;
  }
`;

export function MessengerChat({}) {
  const t = useTranslation();
  const [chatId, setChat] = useUrlParam("chat", null, "string");

  const controlRef = React.useRef(null);
  const messagesRef = React.useRef(null);
  const messagesHeaderRef = React.useRef(null);

  const controlsSize = useElementSize(controlRef);
  const messagesHeaderSize = useElementSize(messagesHeaderRef);
  const chat = useChat(chatId);
  const messages = useChatMessages(chat);

  React.useEffect(() => {
    if (_optionalChain([chat, 'optionalAccess', _ => _.id])) {
      kommuter.chat.markChatAsSeen(chat.id);
    }
  }, [_optionalChain([chat, 'optionalAccess', _2 => _2.id])]);

  React.useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [_optionalChain([messages, 'optionalAccess', _3 => _3.length])]);

  if (!chat) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: t("rs:messenger.chat_not_found_title"),
        message: t("rs:messenger.chat_not_found_message"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 128}}
      )
    );
  }

  const uid = $parse.user.id();

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 139}}
      , React.createElement(ChatHeadContainer, { ref: messagesHeaderRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}
        , React.createElement(List, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}
          , React.createElement(MessengerListItem, { chat: chat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}} )
        )
      )
      , React.createElement(MessagesContainer, {
        ref: messagesRef,
        cHeight: (controlsSize.height || 0) + (messagesHeaderSize.height || 0), __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}

        , messages.length === 0 && React.createElement(Empty, { children: t("rs:messenger.empty"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}} )
        , messages.length > 0 &&
          messages.map((message, i, arr) => {
            const isMyMessage = _optionalChain([message, 'access', _4 => _4.get, 'call', _5 => _5("user"), 'optionalAccess', _6 => _6.id]) === uid;
            const date = dayjs(message.createdAt).format("DD.MM.YYYY");
            const time = dayjs(message.createdAt).format("HH:mm");
            const prevDate =
              i > 0 ? dayjs(arr[i - 1].createdAt).format("DD.MM.YYYY") : null;
            const showDate = date !== prevDate;
            const messageContent = message.get("message");

            return (
              React.createElement(MessageContainer, {
                key: message.id,
                side: isMyMessage ? "right" : "left", __self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}

                , showDate && (
                  React.createElement(MessageDate, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
                    , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}, date)
                  )
                )
                , React.createElement(MessageBubble, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}
                  , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 171}}, messageContent)
                  , React.createElement(MessageTime, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}
                    /* <FormatRelativeDates start={message.createdAt} /> */
                    , React.createElement('span', { children: time, __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}} )
                  )
                )
              )
            );
          })
      )
      , React.createElement(InputContainer, { ref: controlRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 181}}
        , React.createElement(MessengerChatInput, { chat: chat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 182}} )
      )
    )
  );
}
