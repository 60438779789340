import Parse from "parse";













export class QuickMessage extends Parse.Object {
  constructor(data) {
    super("RideSharingQuickMessage", data );
  }
}

Parse.Object.registerSubclass("RideSharingQuickMessage", QuickMessage);
