const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\MessengerList.tsx";import { PageHeader } from "@ant-design/pro-layout";
import { useTranslation } from "@opendash/core";
import { List } from "antd";
import React from "react";
import { ListContainer, MessengerListItem, useChats } from "..";

export function MessengerList() {
  const t = useTranslation();
  const chats = useChats();

  return (
    React.createElement(React.Fragment, null
      , React.createElement(PageHeader, { title: t("rs:messenger.title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}} )
      , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
        , React.createElement(List, {
          dataSource: chats,
          renderItem: (chat) => {
            return React.createElement(MessengerListItem, { chat: chat, hasLink: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}} );
          },
          locale: {
            emptyText: t("rs:messenger.list.empty"),
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
        )
      )
    )
  );
}
