const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\UserPasswordChange.tsx";import { useTranslation } from "@opendash/core";
import { $parse } from "@opendash/plugin-parse";
import { Button, Form, Input } from "antd";
import React from "react";

export function UserPasswordChange() {
  const t = useTranslation();

  return (
    React.createElement('div', { style: { paddingTop: 24, paddingBottom: 24 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}, t("rs:user.password_description"))
      , React.createElement(Form, {
        onFinish: (data) => {
          $parse.auth.changePassword({ password: data.password });
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}

        , React.createElement(Form.Item, {
          name: "password",
          label: t("parse-admin:auth.field_password_label"),
          required: true,
          tooltip: t("parse-admin:auth.field_password_tooltip"),
          rules: [
            {
              required: true,
              message: t("parse-admin:auth.field_password_validation_required"),
            },
            {
              type: "string",
              min: $parse.server.config.AUTH_PASSWORD_MIN_LENGTH,
              message: t("parse-admin:auth.field_password_validation_length", {
                length: $parse.server.config.AUTH_PASSWORD_MIN_LENGTH || "",
              }),
            },
          ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}

          , React.createElement(Input.Password, {
            placeholder: t("parse-admin:auth.field_password_placeholder"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
          )
        )

        , React.createElement(Form.Item, {
          name: "password2",
          label: t("parse-admin:auth.field_password2_label"),
          tooltip: t("parse-admin:auth.field_password2_tooltip"),
          dependencies: ["password"],
          rules: [
            {
              required: true,

              message: t(
                "parse-admin:auth.field_password2_validation_required"
              ),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t("parse-admin:auth.field_password2_validation_missmatch")
                );
              },
            }),
          ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}

          , React.createElement(Input.Password, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 66}} )
        )

        , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
          , React.createElement(Button, { type: "primary", htmlType: "submit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
            , t("rs:user.password_action")
          )
        )
      )
    )
  );
}
