import React from "react";

import { autorun } from "mobx";
import { kommuter, } from "..";

export function useChats() {
  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    return autorun(() => {
      setState(kommuter.chat.chats);
    });
  }, []);

  return state;
}
