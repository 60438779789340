const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\AppRoute.tsx";import { Route, Routes } from "@opendash/router";
import React from "react";
import {
  AppData,
  AppLayout,
  GlobalComponent,
  HomeRoute,
  IntroRoute,
  MessengerRoute,
  RideOfferRoute,
  RideSearchRoute,
  SearchNearPlaceRoute,
  UserSettingsRoute,
} from "..";
import { NewsUpdateNotification } from "./NewsUpdateNotification";

export function AppRoute() {
  return (
    React.createElement(AppLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      , React.createElement(AppData, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
        , React.createElement(GlobalComponent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} )
        , React.createElement(NewsUpdateNotification, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
          , React.createElement(Routes, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
            , React.createElement(Route, { path: "home", element: React.createElement(HomeRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )
            , React.createElement(Route, { path: "search", element: React.createElement(RideSearchRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}} )
            , React.createElement(Route, { path: "offer", element: React.createElement(RideOfferRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} )
            , React.createElement(Route, { path: "messenger", element: React.createElement(MessengerRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}} )
            , React.createElement(Route, { path: "profile", element: React.createElement(UserSettingsRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )
            , React.createElement(Route, { path: "intro", element: React.createElement(IntroRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} )
            , React.createElement(Route, { path: "place/:placeId", element: React.createElement(SearchNearPlaceRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )
          )
        )
      )
    )
  );
}
