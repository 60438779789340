
import { Location } from "..";

export const LocationStorageOptions = {
  encode: (value) => {
    return JSON.stringify(value);
  },
  decode: (value) => {
    return new Location(JSON.parse(value));
  },
};
