import Parse from "parse";













export class NewsCategory extends Parse.Object {
  constructor(data) {
    super("RideSharingNewsCategory", data );
  }
}

Parse.Object.registerSubclass("RideSharingNewsCategory", NewsCategory);
