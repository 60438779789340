import Parse from "parse";








export class Notification extends Parse.Object {
  constructor(data) {
    super("RideSharingNotification", data );
  }
}

Parse.Object.registerSubclass("RideSharingNotification", Notification);
