const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-admin\\components\\StatisticsRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { AdminToolbar } from "@opendash/ui";
import {
  Col,
  Divider,
  Empty,
  Row,
  Slider,
  Space,
  Statistic,
  Typography,
} from "antd";
import Parse from "parse";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import { Map, } from "../../ride-sharing-app";
import { Layer, Source } from "react-map-gl";
import { heatmapLayer } from "./heatmapLayer";
import * as turf from "@turf/turf";

const StyledSlider = styled.div`
  height: 200px;
  & .ant-slider-track {
    width: 30px;
  }
  & .ant-slider-handle,
  .ant-slider-dot,
  .ant-slider-rail {
    display: none;
  }
`;
export function StatisticsRoute() {
  const t = useTranslation();
  const [userCount, setUserCount] = useState(0);
  const [rideCount, setRideCount] = useState(0);
  const [rideCountTotal, setRideCountTotal] = useState(0);
  const [requestsCount, setRideRequests] = useState(0);
  const [visitedPlaces, setVisitedPlaces] = useState(null);
  const [weekCounts, setWeekCounts] = useState(null);
  const init = async () => {
    const users = await new Parse.Query(Parse.User).count();
    const rides = await new Parse.Query("RideSharingOffer").find();
    const requests = await new Parse.Query("RideSharingChat").count();
    const userTenant = Parse.User.current().get("tenant").id;

    const cWeekCounts = rides.reduce(
      (sums, current) => {
        const weekdays = (current.get("weekdays") ).map(
          (day) => day - 1
        );
        weekdays.forEach((day) => {
          sums[day]++;
        });
        return sums;
      },
      [0, 0, 0, 0, 0, 0, 0]
    );

    setWeekCounts(cWeekCounts);
    setUserCount(users);
    const cPlaces = [] 


;
    for (const ride of rides) {
      const places = (await ride.relation("places").query().find()) ;
      const placesFeatures = places.map((place, idx) => {
        return turf.point(
          [place.get("location").longitude, place.get("location").latitude],
          { id: place.id + "_" + idx }
        );
      }) ;

      cPlaces.push(...placesFeatures);
    }

    const fc = turf.featureCollection(cPlaces);
    console.log({ fc });
    setVisitedPlaces(fc);
    setRideCount(
      rides.filter((ride) =>
        _optionalChain([ride, 'access', _2 => _2.getACL, 'call', _3 => _3(), 'optionalAccess', _4 => _4.getRoleReadAccess, 'call', _5 => _5("od-tenant-user-" + userTenant)])
      ).length
    );
    setRideCountTotal(rides.length);
    setRideRequests(requests);
  };
  useEffect(() => {
    init();
  }, []);
  console.log({ visitedPlaces });
  return (
    React.createElement(React.Fragment, null
      , React.createElement(AdminToolbar, {
        title: t("rs:admin.statistics.label"),
        description: t("rs:admin.statistics.description"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
      )
      , React.createElement('div', { style: { background: "#fff", padding: "16px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
        , React.createElement(Divider, { orientation: "left", plain: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
          , t("rs:admin.statistics.overview")
        )
        , React.createElement(Row, { gutter: 16, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
          , React.createElement(Col, { span: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
            , React.createElement(Statistic, {
              style: { border: "1px solid #e8e8e8", padding: 16 },
              prefix: React.createElement(Icon, { icon: "fa:user", __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}} ),
              title: t("rs:admin.statistics.users"),
              value: userCount,
              suffix: t("rs:admin.statistics.users"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
            )
          )

          , React.createElement(Col, { span: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}
            , React.createElement(Statistic, {
              style: { border: "1px solid #e8e8e8", padding: 16 },
              prefix: React.createElement(Icon, { icon: "fa:comment", __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}} ),
              title: t("rs:admin.statistics.requests"),
              value: requestsCount,
              suffix: t("rs:admin.statistics.requests"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
            )
          )
          , React.createElement(Col, { span: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}
            , React.createElement(Statistic, {
              style: { border: "1px solid #e8e8e8", padding: 16 },
              prefix: React.createElement(Icon, { icon: "fa:car", __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}} ),
              title: t("rs:admin.statistics.ridesTotal"),
              value: rideCountTotal,
              suffix: t("rs:admin.statistics.rides"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
            )
          )
          , React.createElement(Col, { span: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
            , React.createElement(Statistic, {
              style: { border: "1px solid #e8e8e8", padding: 16 },
              prefix: React.createElement(Icon, { icon: "fa:car", __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}} ),
              title: t("rs:admin.statistics.ridesTenant"),
              value: rideCount,
              suffix: t("rs:admin.statistics.rides"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
            )
          )
        )
        , React.createElement(Divider, { orientation: "left", plain: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}
          , t("rs:admin.statistics.distribution")
        )
        , weekCounts && (
          React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}
            , React.createElement(Col, {
              span: 8,
              style: {
                verticalAlign: "middle",
                //textAlign: "center",
                margin: "auto",
                fontSize: "3em",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}

              , React.createElement(Space, { direction: "horizontal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}
                , React.createElement(Icon, {
                  icon: "fa:calendar",
                  style: { color: "rgba(0,0,0,0.45)" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}
                )
                , React.createElement(Typography.Text, { type: "secondary", style: { lineHeight: "3em" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
                  , t("rs:admin.statistics.weekdays")
                )
              )
            )
            , Array(7)
              .fill(0)
              .map((_, i) => {
                const marks = {};
                //@ts-ignore
                marks[weekCounts[i]] =
                  weekCounts[i] + " " + t("rs:admin.statistics.rides");
                const dayName = dayjs()
                  .day(i + 1)
                  .format("dddd");
                return (
                  React.createElement(Col, { span: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
                    , React.createElement(Typography.Title, { level: 5, __self: this, __source: {fileName: _jsxFileName, lineNumber: 179}}, dayName)
                    , React.createElement(StyledSlider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 180}}
                      , React.createElement(Slider, {
                        style: { width: 30 },
                        vertical: true,
                        //  marks={marks}
                        value: weekCounts[i],
                        max: Math.max(...weekCounts),
                        min: 0, __self: this, __source: {fileName: _jsxFileName, lineNumber: 181}}
                      )
                    )
                    , React.createElement(Typography.Text, { type: "secondary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}}
                      , weekCounts[i] + " " + t("rs:admin.statistics.rides")
                    )
                  )
                );
              })
          )
        )
        , !weekCounts && React.createElement(Empty, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 198}})
        , React.createElement(Divider, { orientation: "left", plain: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 199}}
          , t("rs:admin.statistics.geodistribution")
        )
        , React.createElement(Row, { style: { height: "300px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 202}}
          , React.createElement(Col, { span: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 203}}
            , visitedPlaces && (
              React.createElement(Map, { width: "100%", height: "100%", __self: this, __source: {fileName: _jsxFileName, lineNumber: 205}}
                , React.createElement(Source, { type: "geojson", data: visitedPlaces, __self: this, __source: {fileName: _jsxFileName, lineNumber: 206}}
                  , React.createElement(Layer, { ...heatmapLayer, __self: this, __source: {fileName: _jsxFileName, lineNumber: 207}} )
                )
                /* <MapPlaces
              places={visitedPlaces || []}
              onSelection={() => {}}
              selection={[]}
            /> */
              )
            )
          )
        )
      )
    )
  );
}
