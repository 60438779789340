 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { usePromise } from "@opendash/core";
import React from "react";

import { runParseRouting } from "../cloud-code";

export function useCloudCodeRouting(start, end) {
  const startLatitude = _optionalChain([start, 'optionalAccess', _ => _.get, 'call', _2 => _2("location"), 'optionalAccess', _3 => _3.latitude]);
  const startLongitude = _optionalChain([start, 'optionalAccess', _4 => _4.get, 'call', _5 => _5("location"), 'optionalAccess', _6 => _6.longitude]);
  const endLatitude = _optionalChain([end, 'optionalAccess', _7 => _7.get, 'call', _8 => _8("location"), 'optionalAccess', _9 => _9.latitude]);
  const endLongitude = _optionalChain([end, 'optionalAccess', _10 => _10.get, 'call', _11 => _11("location"), 'optionalAccess', _12 => _12.longitude]);

  const [result, error, loading] = usePromise(
    async () =>
      !(startLatitude && startLongitude && endLatitude && endLongitude)
        ? []
        : await runParseRouting({
            startLatitude,
            startLongitude,
            endLatitude,
            endLongitude,
          }),
    [startLatitude, startLongitude, endLatitude, endLongitude]
  );

  return React.useMemo(
    () => ({
      error,
      loading,
      result: result || [],
    }),
    [result, error, loading]
  );
}
