 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";

import { autorun } from "mobx";
import { kommuter } from "..";

export function useChatMessages(chat) {
  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    return autorun(() => {
      const allMessages = kommuter.chat.messages;

      const messages = allMessages.filter((m) => m.get("chat").id === _optionalChain([chat, 'optionalAccess', _ => _.id]));

      setState(messages);
    });
  }, [_optionalChain([chat, 'optionalAccess', _2 => _2.id])]);

  return state;
}
