import { usePromise } from "@opendash/core";
import dayjs from "dayjs";
import { runParseSearchPlace } from "../cloud-code";

export function useRideSearchPlace(placeId) {
  const now = dayjs();

  return usePromise(
    () =>
      runParseSearchPlace({
        placeId,
        date: now.valueOf(),
        time: now.format("HH:mm"),
        weekday: now.day(),
      }),
    [placeId, now.minute()]
  );
}
