import { useStorage } from "@opendash/core";
import dayjs from "dayjs";

export function useRideSearchDate() {
  return useStorage(
    "device",
    "ride-sharing:search-date",
    dayjs().startOf("day").valueOf()
  );
}
