const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\MapPlaces.tsx";import React from "react";
import styled from "styled-components";
import { useMap, Marker } from "react-map-gl";



const MarkerDiv = styled.div`
  background-color: ${(p) =>
    p.active
      ? "var(--ant-primary-color)"
      : "var(--ant-primary-color-disabled)"};
  width: 3rem;
  height: 3rem;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
  border: 1px solid var(--ant-primary-color);
`;







export const MapPlaces = React.memo(function MapPlaces({
  places,
  selection,
  onSelection,
}) {
  const { current: map } = useMap();

  return (
    React.createElement(React.Fragment, null
      , places.map((place) => (
        React.createElement(Marker, {
          key: place.id,
          longitude: place.get("location").longitude,
          latitude: place.get("location").latitude,
          anchor: "bottom", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}

          , React.createElement(MarkerDiv, {
            active: selection.includes(place),
            onClick: (event) => {
              event.preventDefault();
              event.stopPropagation();

              if (selection.includes(place)) {
                onSelection(selection.filter((p) => p !== place));
              } else {
                onSelection([...selection, place]);
              }
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
          )
        )
      ))
    )
  );
});
