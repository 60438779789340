const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\HomeRouteContent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import dayjs from "dayjs";
import * as Parse from "parse";
import React from "react";
import {
  ListContainer,
  SearchResultList,
  TitleContainer,
  useRideSearchLocal,
} from "..";

export function HomeRouteContent() {
  const t = useTranslation();

  const name = React.useMemo(() => {
    const full = Parse.User.current().get("name");

    if (!full) {
      return "Anon";
    }

    return full.split(" ")[0];
  }, []);

  const greeting = React.useMemo(() => {
    const now = dayjs();

    const time = now.get("hour");

    if (time < 5) {
      return "night";
    } else if (time < 12) {
      return "morning";
    } else if (time < 18) {
      return "day";
    } else {
      return "evening";
    }
  }, []);

  const [data, error, loading] = useRideSearchLocal();

  return (
    React.createElement(React.Fragment, null
      , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        , t("rs:home.greeting", {
          context: greeting,
          name: name,
        })
      )
      , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}, t("rs:search.offers_nearby"))
      , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
        , React.createElement(SearchResultList, {
          result: _optionalChain([data, 'optionalAccess', _ => _.results]) || [],
          loading: loading,
          startLocation: 
            data
              ? new Parse.GeoPoint({
                  latitude: data.start_latitude,
                  longitude: data.start_longitude,
                })
              : null
          ,
          endLocation: null,
          date: _optionalChain([data, 'optionalAccess', _2 => _2.date]),
          weekday: _optionalChain([data, 'optionalAccess', _3 => _3.weekday]),
          time: _optionalChain([data, 'optionalAccess', _4 => _4.time]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
        )
      )
    )
  );
}
