import Parse from "parse";



export async function runParseGeocode(params




)





 {
  return await Parse.Cloud.run("kommuter-geocode", params);
}

export async function runParseGeocodeReverse(params



)





 {
  return await Parse.Cloud.run("kommuter-geocode-reverse", params);
}

export async function runParseRouting(params




) {
  return await Parse.Cloud.run("kommuter-route", params);
}

export async function runParseSearch(params






)



 {
  return await Parse.Cloud.run("kommuter-search", params);
}

export async function runParseSearchNearby(params





)







 {
  return await Parse.Cloud.run("kommuter-search-nearby", params);
}

export async function runParseSearchPlace(params




)






 {
  return await Parse.Cloud.run("kommuter-search-place", params);
}
