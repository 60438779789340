import Parse from "parse";


























export class Chat extends Parse.Object {
  constructor(data) {
    super("RideSharingChat", data );
  }
}

Parse.Object.registerSubclass("RideSharingChat", Chat);
