 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useObjectState } from "@opendash/core";
import Parse from "parse";
import React from "react";









export function useParseList(
  cls,
  options
) {
  const [state, setState, resetState] = useObjectState({
    result: [],
    count: 0,
    loading: true,
    error: undefined,
    reload: fetch,
  });

  async function fetch() {
    if (cls) {
      // @ts-ignore
      const query = new Parse.Query(cls );

      query.descending("updatedAt");

      if (_optionalChain([options, 'optionalAccess', _ => _.count])) {
        query.withCount();
      }

      if (_optionalChain([options, 'optionalAccess', _2 => _2.includes])) {
        for (const include of options.includes) {
          query.include(include);
        }
      }

      query.find().then(
        (response) => {
          setState({
            result: _optionalChain([options, 'optionalAccess', _3 => _3.count]) ? response.results : response,
            count: _optionalChain([options, 'optionalAccess', _4 => _4.count]) ? response.count : 0,
            loading: false,
          });
        },
        (error) => {
          setState({ error, loading: false });
        }
      );
    }
  }

  React.useEffect(() => {
    setState({
      result: [],
      count: 0,
      loading: true,
      error: undefined,
      reload: fetch,
    });

    fetch();
  }, [cls]);

  return state;
}
