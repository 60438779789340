import React from "react";

import { autorun } from "mobx";
import { kommuter } from "..";

export function useIsOnline() {
  const [state, setState] = React.useState(kommuter.network.connected);

  React.useEffect(() => {
    return autorun(() => {
      setState(kommuter.network.connected);
    });
  }, []);

  return state;
}
