const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\MessengerListItem.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation, useUrlParam } from "@opendash/core";
import { Icon } from "@opendash/icons";
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  List,
  Menu,
  Modal,
  message,
} from "antd";
import dayjs from "dayjs";
import Parse from "parse";
import React from "react";
import { UserBlacklist, UserReport, getProfileImage } from "..";

export function MessengerListItem({
  chat,
  hasLink = false,
}


) {
  const t = useTranslation();
  const [, setChat] = useUrlParam("chat", null, "string");

  const isMyOffer = _optionalChain([chat, 'access', _ => _.get, 'call', _2 => _2("offerUser"), 'optionalAccess', _3 => _3.id]) === Parse.User.current().id;

  const user = chat.get(isMyOffer ? "requestUser" : "offerUser");

  const avatar = getProfileImage(user);

  const title = t(
    isMyOffer ? "rs:messenger.list.title_from" : "rs:messenger.list.title_to",
    {
      name: user.get("name"),
    }
  );

  const start = chat.get("start");
  const end = chat.get("end");

  const date = dayjs(chat.get("date")).format("DD.MM.");
  const time = chat.get("time");
  const s = `${start.get("type")} (${start.get("name")})`;
  const e = `${end.get("type")} (${end.get("name")})`;

  const description = `${date} @ ${time}, ${s} →  ${e}`;

  function blockUser() {
    Modal.confirm({
      title: t("rs:user.blacklist.confirm_title"),
      content: t("rs:user.blacklist.confirm_content"),
      okText: t("rs:user.blacklist.ok"),
      okType: "danger",
      onOk: () => {
        const blacklist = new UserBlacklist({
          user: Parse.User.current(),
          target: user,
        });

        blacklist.save().then(
          (ok) => {
            setChat(null);
            message.success(t("rs:user.blacklist.success"));
          },
          (error) => {
            console.error(error);
            message.success(t("rs:user.blacklist.error"));
          }
        );
      },
    });
  }

  function reportUser() {
    Modal.confirm({
      title: t("rs:user.report.confirm_title"),
      content: t("rs:user.report.confirm_content"),
      okText: t("rs:user.report.ok"),
      okType: "danger",
      onOk: () => {
        const report = new UserReport({
          user: Parse.User.current(),
          target: user,
          message: "",
        });

        report.save().then(
          (ok) => {
            setChat(null);
            message.success(t("rs:user.report.success"));
          },
          (error) => {
            console.error(error);
            message.success(t("rs:user.report.error"));
          }
        );
      },
    });
  }

  function deleteChat() {
    Modal.confirm({
      title: t("rs:messenger.delete.confirm_title"),
      content: t("rs:messenger.delete.confirm_content"),
      okText: t("rs:messenger.delete.ok"),
      okType: "danger",
      onOk: () => {
        Parse.Cloud.run("kommuter-delete-chat", { chatId: chat.id }).then(
          (ok) => {
            setChat(null);
            message.success(t("rs:messenger.delete.success"));
          },
          (error) => {
            console.error(error);
            message.success(t("rs:messenger.delete.error"));
          }
        );
      },
    });
  }

  const menu = (
    React.createElement(Menu, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
      , React.createElement(Menu.Item, {
        key: "blacklist",
        onClick: blockUser,
        title: t("rs:user.blacklist.action_title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}}

        , React.createElement(Icon, { icon: "ai:stop", style: { marginRight: 10 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}} )
        , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}, t("rs:user.blacklist.action"))
      )
      , React.createElement(Menu.Item, {
        key: "report",
        onClick: reportUser,
        title: t("rs:user.report.action_title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}

        , React.createElement(Icon, { icon: "ai:exclamation-circle", style: { marginRight: 10 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}} )
        , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}, t("rs:user.report.action"))
      )
      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 143}} )
      , React.createElement(Menu.Item, {
        key: "delete",
        onClick: deleteChat,
        title: t("rs:messenger.delete.action_title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}

        , React.createElement(Icon, { icon: "fa:trash", style: { marginRight: 10 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}} )
        , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}, t("rs:messenger.delete.action"))
      )
    )
  );

  return (
    React.createElement(List.Item, {
      onClick: () => {
        if (hasLink) {
          setChat(chat.id);
        }
      },
      extra: 
        hasLink ? (
          React.createElement(Button, {
            type: "link",
            icon: React.createElement(Icon, { icon: "ai:right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}} ),
            children: t("rs:messenger.list.open_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 164}}
          )
        ) : (
          React.createElement(Dropdown, {
            overlay: menu,
            trigger: ["click"],
            children: 
              React.createElement(Button, {
                type: "default",
                shape: "circle",
                icon: React.createElement(Icon, { icon: "ai:more", __self: this, __source: {fileName: _jsxFileName, lineNumber: 177}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}
              )
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}
          )
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}

      , React.createElement(List.Item.Meta, {
        title: title,
        description: description,
        avatar: 
          React.createElement(Avatar, {
            icon: avatar ? undefined : React.createElement(Icon, { icon: "ai:user", __self: this, __source: {fileName: _jsxFileName, lineNumber: 189}} ),
            src: avatar ? avatar : undefined,
            size: "large", __self: this, __source: {fileName: _jsxFileName, lineNumber: 188}}
          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 184}}
      )
    )
  );
}
