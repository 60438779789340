const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\TimeInput.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }
import { Icon } from "@opendash/icons";
import { Drawer, Input } from "antd";
import React from "react";
import styled from "styled-components";

const CELL_HEIGHT = 80;

const Wrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;

  display: flex;
`;

const SelectionIndicatorTop = styled.div`
  position: absolute;
  width: 100%;
  height: 0px;

  top: ${CELL_HEIGHT}px;
  left: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
const SelectionIndicatorBottom = styled.div`
  position: absolute;
  width: 100%;
  height: 0px;

  top: ${CELL_HEIGHT * 2}px;
  left: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const Column = styled.div`
  width: 50%;
  height: ${CELL_HEIGHT * 3}px;
  overflow-y: auto;

  padding-top: ${CELL_HEIGHT}px;
  padding-bottom: ${CELL_HEIGHT}px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:not(:last-child) {
    // border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const Option = styled.button`
  display: block;
  width: 100%;

  height: ${CELL_HEIGHT}px;

  background: transparent;
  border: 0;
  outline: 0;

  &.active {
    // border: 1px solid red;

    font-weight: bold;
  }
`;

const hours = new Array(24).fill(0).map((x, i) => i);
const minutes = new Array(6).fill(0).map((x, i) => i * 10);

export function TimeInput({ time, setTime, style }) {
  const [open, setOpen] = React.useState(false);

  const timeParsed = (time || "8:0").split(":").map((x) => parseInt(x));
  const currentHour = timeParsed[0] || 0;
  const currentMinute = timeParsed[1] || 0;

  const setHour = (h) => {
    setTime(
      h + ":" + (currentMinute >= 10 ? currentMinute : "0" + currentMinute)
    );
  };

  const setMinute = (m) => {
    setTime(currentHour + ":" + (m >= 10 ? m : "0" + m));
  };

  return (
    React.createElement(React.Fragment, null
      /* <TimePickerStyle />
      <TimePicker
        size="large"
        style={style}
        allowClear={false}
        minuteStep={10}
        value={dayjs(time || "8:00", timeFormat)}
        format={timeFormat}
        popupClassName={"custom-time-picker-body"}
        // onChange={(date) => {
        //   setTime(date.format(timeFormat));
        // }}
        onSelect={(date) => {
          setTime(date.format(timeFormat));
        }}
      /> */

      , React.createElement(Input, {
        size: "large",
        style: style,
        value: time,
        onClick: () => {
          setOpen(true);
        },
        suffix: React.createElement(Icon, { icon: "fa:clock", style: { color: "rgba(0,0,0,.25)" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
      )

      , React.createElement(Drawer, {
        destroyOnClose: true,
        open: open,
        placement: "bottom",
        height: CELL_HEIGHT * 3,
        bodyStyle: { padding: 0 },
        headerStyle: { display: "none" },
        onClose: () => {
          setOpen(false);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}

        , React.createElement(Wrapper, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
          , React.createElement(SelectionIndicatorTop, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 134}} )
          , React.createElement(SelectionIndicatorBottom, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 135}} )

          , React.createElement(TimeInputColumn, {
            values: hours,
            value: currentHour,
            onChange: (value) => {
              setHour(value);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
          )
          , React.createElement(TimeInputColumn, {
            values: minutes,
            value: currentMinute,
            onChange: (value) => {
              setMinute(value);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}
          )
        )
      )
    )
  );
}

function TimeInputColumn({
  values,
  value,
  onChange,
}



) {
  const columnRef = React.useRef();
  const onChangeRef = React.useRef(null);
  const scrollTimeoutRef = React.useRef(null);
  const scrollInitialRef = React.useRef(true);

  onChangeRef.current = onChange;

  React.useEffect(() => {
    if (columnRef.current) {
      columnRef.current.scrollTo({
        top: Math.max(0, values.indexOf(value) * CELL_HEIGHT),
        behavior: scrollInitialRef.current ? undefined : "smooth",
      });

      scrollInitialRef.current = false;
    }
  }, [values, value, columnRef.current]);

  React.useEffect(() => {
    const el = columnRef.current;
    if (el) {
      const handler = function () {
        if (scrollTimeoutRef.current !== null) {
          clearTimeout(scrollTimeoutRef.current);
        }

        scrollTimeoutRef.current = setTimeout(() => {
          // select value based on current scroll position

          const scrollOffset = el.scrollTop;

          const index = Math.round(scrollOffset / CELL_HEIGHT);

          const newValue = values[index];

          if (value !== newValue) {
            onChangeRef.current(newValue);
          }
        }, 100);
      };

      el.addEventListener("scroll", handler, false);

      return () => {
        _optionalChain([el, 'optionalAccess', _ => _.removeEventListener, 'call', _2 => _2("scroll", handler)]);

        if (scrollTimeoutRef.current !== null) {
          clearTimeout(scrollTimeoutRef.current);
        }
      };
    }
  }, [columnRef.current, value, values]);

  return (
    React.createElement(Column, { ref: columnRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 220}}
      , values.map((v) => (
        React.createElement(Option, {
          key: v,
          className: v === value ? "active" : undefined,
          onClick: () => onChange(v), __self: this, __source: {fileName: _jsxFileName, lineNumber: 222}}

          , v
        )
      ))
    )
  );
}
