const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\RideOfferCreate.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation, useUrlParam } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, message } from "antd";
import Parse from "parse";
import React from "react";
import {
  createParsePolygonFromRoute,
  FormElement,

  LocationInput,
  Offer,
  OfferRoute,

  PlacesInput,
  RoutesInput,
  TimeInput,
  useCloudCodeRouting,
  usePlacesNearRoute,
  WeekdayInput,
} from "..";

const containerStyle = {
  padding: 24,
};

const switchButtonStyle = {
  display: "block",
  zIndex: 1,
  marginTop: -28,
  marginBottom: -4,
  marginLeft: "auto",
  marginRight: 0,
  transform: "rotate(90deg)",
};

const inputWithLabelStyle = {
  width: "100%",
};

const submitButtonStyle = {
  width: "100%",
  // marginTop: 14,
  marginBottom: 24,
};

export function RideOfferCreate() {
  const t = useTranslation();

  const [open, setOpen] = useUrlParam("create", false, "json");

  const [start, setStart] = React.useState();
  // // @ts-ignore
  // Parse.Object.fromJSON({
  //   className: "RideSharingLocation",
  //   location: {
  //     __type: "GeoPoint",
  //     latitude: 50.9112587,
  //     longitude: 8.025339800000001,
  //   },
  //   google: "ChIJN0h22kkDvEcRYOuert7Ywr4",
  //   description:
  //     "Universität Siegen, Adolf-Reichwein-Straße, Siegen, Deutschland",
  //   createdAt: "2020-06-17T19:32:11.151Z",
  //   updatedAt: "2020-06-18T07:16:54.935Z",
  //   ACL: {
  //     "5Fn6FJSnzi": {
  //       read: true,
  //       write: true,
  //     },
  //   },
  //   objectId: "aoGoCHzsvq",
  // })

  const [end, setEnd] = React.useState();
  // //@ts-ignore
  // Parse.Object.fromJSON({
  //   className: "RideSharingLocation",
  //   location: {
  //     __type: "GeoPoint",
  //     latitude: 50.8732147,
  //     longitude: 8.023481399999998,
  //   },
  //   google: "ChIJj33v9JEcvEcRRKZ3_kIyI7Y",
  //   description: "Kohlbettstraße 15, Siegen, Deutschland",
  //   createdAt: "2020-06-17T19:31:53.084Z",
  //   updatedAt: "2020-06-18T07:16:52.528Z",
  //   ACL: {
  //     "5Fn6FJSnzi": {
  //       read: true,
  //       write: true,
  //     },
  //   },
  //   objectId: "cE2lPaHyoT",
  // })

  const [routeNr, setRouteNr] = React.useState(0);
  const [places, setPlaces] = React.useState([]);
  const [time, setTime] = React.useState("8:30");
  const [weekdays, setWeekdays] = React.useState(
    [1, 2, 3, 4, 5]
  );

  const { result: routes, loading: routesLoading } = useCloudCodeRouting(
    start,
    end
  );
  const route = _optionalChain([routes, 'optionalAccess', _ => _[routeNr]]);
  const { result: placesNearRoute } = usePlacesNearRoute(route, start, end);

  return (
    React.createElement('div', { style: containerStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
      , React.createElement(FormElement, { label: t("rs:ride.start_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
        , React.createElement(LocationInput, {
          style: inputWithLabelStyle,
          label: t("rs:ride.start_select"),
          urlParam: "select_start",
          location: start,
          setLocation: (location) => setStart(location), __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
        )
      )

      , React.createElement(Button, {
        size: "large",
        shape: "circle",
        type: "primary",
        style: switchButtonStyle,
        icon: React.createElement(Icon, { icon: "ai:retweet", __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}} ),
        title: t("rs:search.switch"),
        disabled: !start || !end,
        onClick: (e) => {
          setStart(end);
          setEnd(start);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
      )

      , React.createElement(FormElement, { label: t("rs:ride.end_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
        , React.createElement(LocationInput, {
          style: inputWithLabelStyle,
          label: t("rs:ride.end_select"),
          urlParam: "select_end",
          location: end,
          setLocation: (location) => setEnd(location), __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
        )
      )

      , React.createElement(FormElement, { label: t("rs:ride.route_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
        , React.createElement(RoutesInput, {
          disabled: !start || !end,
          loading: routesLoading,
          routes: routes,
          places: placesNearRoute,
          placesSelected: places,
          onPlaceSelection: setPlaces,
          label: t("rs:ride.route_label"),
          style: inputWithLabelStyle,
          value: routeNr,
          urlParam: "select_route",
          onChange: setRouteNr, __self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}
        )
      )

      , React.createElement(FormElement, { label: t("rs:ride.places_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
        , React.createElement(PlacesInput, {
          pool: placesNearRoute,
          urlParam: "select_places",
          label: t("rs:ride.places_label"),
          description: t("rs:ride.places_description"),
          style: inputWithLabelStyle,
          value: places,
          onChange: setPlaces, __self: this, __source: {fileName: _jsxFileName, lineNumber: 163}}
        )
      )

      , React.createElement(FormElement, { label: t("rs:weekdays.label"), useLabel: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}
        , React.createElement(WeekdayInput, { value: weekdays, setValue: setWeekdays, __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}} )
      )

      , React.createElement(FormElement, { label: t("rs:ride.time_label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
        , React.createElement(TimeInput, { time: time, setTime: setTime, style: inputWithLabelStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 179}} )
      )

      , React.createElement(Button, {
        size: "large",
        type: "primary",
        style: submitButtonStyle,
        disabled: !start || !end || !_optionalChain([weekdays, 'optionalAccess', _2 => _2.length]),
        children: t("rs:offer.create.submit"),
        title: 
          !start || !end || !_optionalChain([weekdays, 'optionalAccess', _3 => _3.length])
            ? t("rs:offer.create.submit_tooltip_disabled")
            : t("rs:offer.create.submit_tooltip")
        ,
        onClick: (e) => {
          const offerRoute = new OfferRoute({
            start,
            end,
            route: createParsePolygonFromRoute(route),
            routeFull: route,
          });

          const offer = new Offer({
            user: Parse.User.current(),
            route: offerRoute,
            time,
            weekdays,
            active: true,
          });

          places.forEach((place) => {
            offer.relation("places").add(place);
          });

          offer.save().then(
            () => {
              message.success(t("rs:offer.create.success"));
              setOpen(false);
            },
            (error) => {
              message.error(t("rs:offer.create.error"));
              console.error(error);
            }
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}
      )
    )
  );
}
