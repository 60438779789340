const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\AppLayout.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { $framework, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { NavLink } from "@opendash/router/dist/internal";
import { Badge } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { AppGlobalStyle, getUserProfile, kommuter } from "..";

const Container = styled.div`
  width: 100%;

  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
  padding-bottom: calc(
    var(--kommuter-navbar-bottom-height) + 10px + env(safe-area-inset-bottom)
  );
  user-select: none;
`;

const NavigationContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;

  width: 100%;
  height: calc(
    var(--kommuter-navbar-bottom-height) + env(safe-area-inset-bottom)
  );
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);

  z-index: 99;

  a {
    display: block;
    background: white;
    width: 100%;
    text-align: center;
    color: inherit;
    height: var(--kommuter-navbar-bottom-height);
    padding: 6px 0;
    text-decoration: none;

    &.active {
      border-bottom: 4px solid var(--ant-primary-color);
      color: var(--ant-primary-color);
      font-weight: bold;
    }
  }
`;

const IconHolder = styled.div`
  font-size: 20px;
  line-height: 26px;
`;

const Label = styled.div`
  font-size: 11px;
  line-height: 20px;
`;

const BodyContainer = styled.div``;

export const AppLayout = observer(function AppLayout({
  children,
}) {
  const t = useTranslation();

  const offerDisabled = _optionalChain([getUserProfile, 'call', _ => _(), 'optionalAccess', _2 => _2.offerDisabled]);
  const homeDisabled = _optionalChain([getUserProfile, 'call', _3 => _3(), 'optionalAccess', _4 => _4.homeDisabled]);

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
      , React.createElement(AppGlobalStyle, {
        footer: $framework.router.pathname === "/ride-sharing/profile", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
      )

      , React.createElement(NavigationContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
        , !homeDisabled && (
          React.createElement(NavLink, { to: "home", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
            , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
              , React.createElement(Icon, { icon: "ai:home", __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}} )
            )
            , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}, t("rs:navigation.home"))
          )
        )

        , React.createElement(NavLink, { to: "search", __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
          , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
            , React.createElement(Icon, { icon: "ai:search", __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}} )
          )
          , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}, t("rs:navigation.search"))
        )

        , !offerDisabled && (
          React.createElement(NavLink, { to: "offer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
            , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
              , React.createElement(Icon, { icon: "ai:car", __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}} )
            )
            , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}, t("rs:navigation.offer"))
          )
        )

        , React.createElement(NavLink, { to: "messenger", __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
          , React.createElement(Badge, {
            count: kommuter.chat.count,
            color: "var(--ant-primary-color)",
            offset: [-10, 0], __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}

            , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
              , React.createElement(Icon, { icon: "ai:message", __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}} )
            )
            , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}, t("rs:navigation.messenger"))
          )
        )

        , React.createElement(NavLink, { to: "profile", __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
          , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
            , React.createElement(Icon, { icon: "ai:setting", __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}} )
          )
          , React.createElement(Label, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}, t("rs:navigation.settings"))
        )
      )
      , React.createElement(BodyContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}, children)
    )
  );
});
