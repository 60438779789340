const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\LocationSearch.tsx";import { useTranslation } from "@opendash/core";
import { Divider, Drawer, Input } from "antd";
import React from "react";
import {
  Location,
  LocationList,
  useCloudCodeGeoCoding,
  useCurrentLocation,
  useParseList,
} from "..";








export const LocationSearch = React.memo(
  ({ open, label, onChange, onClose }) => {
    const t = useTranslation();
    const [query, setQuery] = React.useState("");

    const { loading: recentLoading, result: recentLocations } = useParseList(
      open ? Location : null
    );

    const { loading: searchLoading, result: searchResult } =
      useCloudCodeGeoCoding(query);

    const currentLocation = useCurrentLocation();

    const suggestions = React.useMemo(
      () =>
        (query ? searchResult : [currentLocation, ...recentLocations]).filter(
          Boolean
        ),
      [query, currentLocation, recentLocations, searchResult]
    );

    const selectLocation = (item) => {
      if (!item.id) {
        const recent = recentLocations.find(
          (loc) =>
            item.get("title") === loc.get("title") &&
            item.get("description") === loc.get("description")
        );

        if (recent) {
          item = recent;
        }
      }

      item.save({ icon: null }).then(() => {
        onChange(item);
        onClose();
      });
    };

    React.useEffect(() => {
      if (!open) {
        setQuery("");
      }
    }, [open]);

    return (
      React.createElement(Drawer, {
        open: open,
        title: label,
        placement: "bottom",
        height: Math.min(500, window.innerHeight),
        onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}

        , React.createElement(Input.Search, {
          loading: query && searchLoading,
          value: query,
          onChange: (e) => {
            setQuery(e.target.value);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
        )

        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 82}} )

        , React.createElement(LocationList, {
          loading: query ? searchLoading : recentLoading,
          locations: suggestions,
          onChange: selectLocation, __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
        )
      )
    );
  }
);
