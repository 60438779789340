const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\RoutesInput.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {
  formatDistance,
  formatRelativeDates,
  useTranslation,
  useUrlParam,
} from "@opendash/core";
import { Button, Drawer } from "antd";
import React from "react";
import { RoutesMap } from "..";















export function RoutesInput({
  disabled,
  loading,
  routes,
  places,
  placesSelected,
  onPlaceSelection,
  value = 0,
  label,
  urlParam,
  style,
}) {
  const t = useTranslation();

  const [open, setOpen] = useUrlParam(urlParam, false, "json");

  const route = _optionalChain([routes, 'optionalAccess', _ => _[value]]);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Button, {
        size: "large",
        style: style,
        loading: loading,
        disabled: disabled,
        children: route ? getName(route) : t("rs:ride.route_select"),
        title: label,
        onClick: (e) => setOpen(true), __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
      )

      , React.createElement(Drawer, {
        open: open,
        title: label,
        placement: "bottom",
        height: "calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))"    ,
        bodyStyle: { padding: 0 },
        onClose: () => {
          setOpen(false);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}

        , React.createElement(RoutesMap, {
          routes: routes,
          route: route,
          places: places,
          placesSelected: placesSelected,
          onPlaceSelection: onPlaceSelection, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
        )
      )
    )
  );
}

function getName(route) {
  return (
    formatDistance(route.distance * 1000) +
    " " +
    formatRelativeDates(Date.now() + route.time, Date.now())
  );
}
