import { makeAutoObservable } from "mobx";

import {
  Geolocation as CapacitorGeolocation,

} from "@capacitor/geolocation";



export class GeolocationService {
  

   __init() {this.latitude = 0}
   __init2() {this.longitude = 0}
   __init3() {this.accuracy = 0}
   __init4() {this.altitudeAccuracy = 0}
   __init5() {this.altitude = 0}
   __init6() {this.speed = 0}
   __init7() {this.heading = 0}

   __init8() {this.updatedAt = Date.now()}

   __init9() {this.geolocationWatcher = null}

  constructor(kommuter) {;GeolocationService.prototype.__init.call(this);GeolocationService.prototype.__init2.call(this);GeolocationService.prototype.__init3.call(this);GeolocationService.prototype.__init4.call(this);GeolocationService.prototype.__init5.call(this);GeolocationService.prototype.__init6.call(this);GeolocationService.prototype.__init7.call(this);GeolocationService.prototype.__init8.call(this);GeolocationService.prototype.__init9.call(this);
    this.kommuter = kommuter;

    makeAutoObservable(this);
  }

   setPosition(position) {
    if (
      this.latitude === 0 ||
      Math.abs(position.timestamp - this.updatedAt) > 1000 * 60
    ) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      this.accuracy = position.coords.accuracy;
      this.altitudeAccuracy = position.coords.altitudeAccuracy;
      this.altitude = position.coords.altitude;
      this.speed = position.coords.speed;
      this.heading = position.coords.heading;

      this.updatedAt = position.timestamp;
    }
  }

   async init() {
    try {
      const currentPosition = await CapacitorGeolocation.getCurrentPosition();

      this.setPosition(currentPosition);

      this.geolocationWatcher = await CapacitorGeolocation.watchPosition(
        { timeout: 10000 },
        (position, error) => {
          if (error) {
            return console.error("Geolocation.watchPosition() Error", error);
          }

          this.setPosition(position);
        }
      );
    } catch (error) {
      console.error("Geolocation.init() Error", error);
    }
  }

   close() {
    if (this.geolocationWatcher) {
      CapacitorGeolocation.clearWatch({ id: this.geolocationWatcher }).catch(
        (error) => {
          console.error("Geolocation.clearWatch() Error:", error);
        }
      );
    }
  }
}
