import dayjs from "dayjs";
import React from "react";
import {
  Offer,
  useRideSearchDate,
  useRideSearchEnd,
  useRideSearchStart,
  useRideSearchTime,
} from "..";
import { runParseSearch } from "../cloud-code";

export function useRideSearch() {
  const [state, setState] = React.useState([]);

  const [start] = useRideSearchStart();
  const [end] = useRideSearchEnd();
  const [time] = useRideSearchTime();
  const [date] = useRideSearchDate();

  React.useEffect(() => {
    if (start && end) {
      fetchOffers({
        start_latitude: start.get("location").latitude,
        start_longitude: start.get("location").longitude,
        end_latitude: end.get("location").latitude,
        end_longitude: end.get("location").longitude,
        weekday: dayjs(date).weekday(),
        time,
        date,
      }).then(
        (result) => {
          setState(result);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [start, end]);

  return state;
}

async function fetchOffers(params) {
  const result = await runParseSearch(params);

  const offers = result.offers.map(
    (id) => Offer.createWithoutData(id) 
  );

  await Offer.fetchAll(offers, {});

  for (const offer of offers) {
    const user = offer.get("user");

    await user.fetch();

    const profile = user.get("rsProfile");

    await profile.fetch();
  }

  return offers;
}
