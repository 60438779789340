const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\RideOfferResultList.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { $framework, useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Avatar, Button, List } from "antd";
import dayjs from "dayjs";
import React from "react";
import {
  $kommuter,
  ListEmpty,

  getProfileImage,
  useChats,
  useRideSearchDate,
  useRideSearchEnd,
  useRideSearchStart,
  useRideSearchTime,
} from "..";

export function RideOfferResultList({ data }) {
  const t = useTranslation();
  const chats = useChats();

  const [start] = useRideSearchStart();
  const [end] = useRideSearchEnd();
  const [time] = useRideSearchTime();
  const [date] = useRideSearchDate();

  const startLocation = start.get("location");
  const endLocation = end.get("location");
  const weekday = dayjs(date).day();

  if (data.length === 0) {
    return React.createElement(ListEmpty, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}, t("rs:search.results.offers_empty"));
  }

  return (
    React.createElement(List, {
      dataSource: data,
      renderItem: (offer) => {
        const user = offer.get("user");
        const avatar = getProfileImage(user);
        const chat = chats.find((c) => _optionalChain([c, 'access', _ => _.get, 'call', _2 => _2("offer"), 'optionalAccess', _3 => _3.id]) === offer.id);

        const onClick = () => {
          if (chat) {
            $framework.router.navigate(
              "/ride-sharing/messenger?chat=" + chat.id
            );
          } else {
            $kommuter.offers.requestOffer({
              offerId: offer.id,
              startLocation,
              endLocation,
              date,
              weekday,
              time,
            });
          }
        };

        return (
          React.createElement(List.Item, {
            onClick: onClick,
            extra: 
              chat ? (
                React.createElement(Button, { children: t("rs:search.request_open"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} )
              ) : (
                React.createElement(Button, {
                  type: "primary",
                  children: t("rs:search.request_create"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
                )
              )
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}

            , React.createElement(List.Item.Meta, {
              avatar: 
                React.createElement(Avatar, {
                  icon: avatar ? undefined : React.createElement(Icon, { icon: "ai:user", __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}} ),
                  src: avatar ? avatar : undefined,
                  size: "large", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
                )
              ,
              title: t("rs:offer.results.title", { name: user.get("name") }),
              description: t("rs:offer.results.description", {
                time: offer.get("time"),
              }), __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
            )
          )
        );
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
    )
  );
}
