const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\KOMMUTER\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\UserLogout.tsx";import { useOpenDashServices, useTranslation } from "@opendash/core";
import { Button } from "antd";
import React from "react";

export function UserLogout() {
  const t = useTranslation();

  const { UserService } = useOpenDashServices();

  return (
    React.createElement('div', { style: { paddingTop: 24, paddingBottom: 24 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, t("rs:user.logout.description"))
      , React.createElement(Button, {
        children: t("rs:user.logout.action"),
        style: { width: "100%" },
        onClick: () => {
          UserService.logout();
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
      )
    )
  );
}
